import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import {
  IconButton,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import CropFreeIcon from "@material-ui/icons/CropFree";
import AssignmentIcon from "@material-ui/icons/Assignment";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import AsyncAutocompleteSearch from "../../components/AsyncAutocompleteSearch";

import { useModels } from "../../hooks/useModels";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { set } from "date-fns";
import { CloudDownload } from "@material-ui/icons";

const actasRequestDefault = {
  name: null,
  fields: "nombreCompletoActa",
  expand: "diferenciaIgualdad",
  extraParams: {
    estatusDiferencia: "con-diferencia",
  },
};

const ReporteEIEDEA = ({ dialog, setEditingConsejo, setSelectedConsejo }) => {
  const history = useHistory();
  const header = [];
  const [nombre, setNombre] = React.useState("");
  const [idConsejoElectoral, setIdConsejoElectoral] = React.useState("");
  const [actasRequest, setActasRequest] = React.useState(actasRequestDefault);

  const consejoElectoralParamsMemo = React.useMemo(
    () => ({ idEstado: 26, limite: -1, ordenar: "nombre.asc" }),
    []
  );
  const [
    consejosElectorales,
    consejosElectoralesLoading,
    consejosElectoralesError,
    _,
    refreshModels,
  ] = useModels({
    name: "consejo-electoral",
    expand: "estado,municipio,distrito,tipoConsejoElectoral",
    ordenar: "nombre.asc",
  });

  const [actas, actasLoading] = useModels(actasRequest);

  const setIdConsejoElectoralCallback = React.useCallback((e, v) => {
    if (v !== "" && v) {
      const { idConsejoElectoral, idMunicipio, idDistrito } = v;
      setIdConsejoElectoral(v.idConsejoElectoral);
      setActasRequest({
        name: "acta",
        fields: "nombreCompletoActa",
        expand: "diferenciaIgualdad",
        extraParams: {
          idConsejoElectoral,
          idMunicipio,
          idDistrito,
          estatusDiferencia: "con-diferencia",
        },
      });
    } else {
      setIdConsejoElectoral("");
    }
  }, []);

  const onSearchClicked = async () => {
    let params = {};
    if (nombre.length > 0) params = { ...params, nombre };
    if (idConsejoElectoral > 0) params = { ...params, idConsejoElectoral };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
  };

  // const changePage = (page) => {
  //   let params = {
  //     name: "consejo-electoral",
  //     expand: "estado,municipio,distrito,tipoConsejoElectoral",
  //     ordenar: "nombre.asc",
  //     pagina: page + 1,
  //     limite: 20,
  //     isCargando: false,
  //     idConsejoElectoral,
  //     // idDistrito,
  //   };
  //   refreshModels(true, params);
  // };

  const downloadClicked = (_) => {
    window.open(
      "https://dev.computosonora.com/v1/excel/estadisticos-eiedea?id=fzIxjAB2ZNXtAkolKFAc2wjfmyd2",
      "_blank"
    );
  };

  return (
    <TablePageLayout
      title="REPORTES DE DIFERENCIAS DE IGUALDAD"
      model="consejos-electorales"
      history={history}
      links={[
        {
          to: "/reportes-eiedea",
          icon: <CropFreeIcon />,
          label: "Reportes EIEDEA",
        },
      ]}
      actionButton={{
        onClick: downloadClicked,
        label: "DESCARGAR REPORTE",
        icon: <CloudDownload />,
      }}
      SearchComponents={
        <>
          <Grid item xs={12} sm={5}>
            <AsyncAutocompleteSearch
              label="Consejo Electoral"
              name="consejo-electoral"
              labelProp="nombre"
              onChange={setIdConsejoElectoralCallback}
              extraParams={consejoElectoralParamsMemo}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={consejosElectoralesLoading}
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={actasLoading}
      tableErrors={consejosElectoralesError}
      TableComponent={
        <>
          {actas && actas.length > 0 && (
            <TablePagination
              header={header}
              data={actas}
              paginatedApi
              // changePageApi={changePage}
              // count={modelsPage ? modelsPage.total : 0}
              extraRows={[
                {
                  prop: "nombreCompletoActa",
                  name: "CASILLA - TIPO",
                  cell: (row) => (
                    <>{row.nombreCompletoActa ? row.nombreCompletoActa : ""}</>
                  ),
                },
                {
                  prop: "diferenciaIgualdad",
                  name: "Diferencia de igualdad",
                  cell: (row) => <>{row.diferenciaIgualdad}</>,
                },
                // {
                //   prop: "acciones",
                //   name: "Acciones",
                //   cell: (row, index) => (
                //     <div>
                //       <Button
                //         variant="outlined"
                //         color="primary"
                //         onClick={() => null}
                //       >
                //         VER DETALLE
                //       </Button>
                //     </div>
                //   ),
                // },
              ]}
            />
          )}
          {idConsejoElectoral !== "" && actas && actas.length === 0 && (
            <Grid container>
              <Grid item xs={6}>
                <Typography style={{ fontSize: 24, marginTop: 100 }}>
                  No se encontraron resultados para este Consejo Electoral.
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );
};

export default ReporteEIEDEA;
