import PERMISSIONS_BASE, {
  auxCaptura,
  auxDatosGenerales,
  presidenteDistrital,
  auxLectura,
  auxRecepcion,
  encargadoDeBodega,
  permisoExtraBodegaAdministracion as encargado,
  permisoExtraRecepcionPaquetes as recepcion,
  presidenteDeConsejo,
  presidenteMunicipal,
  recepcionPaquetes,
  auxAcreditacion,
} from "../constants/permissions";

const capitalizeFirst = (string) => {
  const split = string.split("-");
  let palabraUnida = "";
  split.forEach((s) => {
    palabraUnida = palabraUnida + s.charAt(0).toUpperCase() + s.slice(1);
  });
  return palabraUnida;
};
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateRfc = (rfcString) => {
  let re =
    /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;
  return re.test(String(rfcString).toLowerCase());
};

const validateName = (name) => {
  let re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  return re.test(name);
};

const validateUser = (user) => {
  let re = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
  return re.test(user);
};

const validatePassword = (passwd) => {
  let re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return re.test(passwd);
};

const validateConfirmPassword = (passwd, passwd2) => {
  if (passwd2 !== passwd) return false;
  else return true;
};

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

const propertyAccesor = (rootObj, accesor = "") => {
  if (!rootObj) return "";
  const properties = accesor.split(".");
  let tmp = rootObj;
  properties.forEach((prop) => (tmp = tmp[prop]));
  return tmp.toString();
};

const generateDefaultChartOptions = (chartType = "pie", options = {}) => ({
  chart: {
    type: chartType,
    inverted: options.inverted || false,
    options3d: {
      enabled: chartType === "pie",
      alpha: 45,
      beta: 0,
    },
    height: options.chartHeight || null,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: options.title || "TITULO POR DEFAULT",
  },
  subtitle: {
    text: options.subtitle || "SUBTITULO POR DEFAULT",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
    },
  },
  series: [
    {
      name: options.name || "NOMBRE DE LA COLECCION DE DATOS",
      data: options.data || [],
    },
  ],
});

const generateDefaultChartOptions2D = (chartType = "pie", options = {}) => ({
  chart: {
    type: chartType,
    inverted: options.inverted || false,
    height: options.chartHeight || null,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: options.title || "TITULO POR DEFAULT",
  },
  subtitle: {
    text: options.subtitle || "SUBTITULO POR DEFAULT",
  },
  plotOptions: {
    pie: {},
  },
  series: [
    {
      name: options.name || "NOMBRE DE LA COLECCION DE DATOS",
      data: options.data || [],
    },
  ],
});

const checkPermissionPreset = (preset, { permissionKey, permissionValue }) => {
  if (preset === 1 && encargadoDeBodega.includes(permissionKey)) {
    if (permissionKey === "bodegaAdministracion") {
      return (
        (permissionValue & encargado.READ) === encargado.READ ||
        (permissionValue & encargado.OPEN) === encargado.OPEN ||
        (permissionValue & encargado.CLOSE) === encargado.CLOSE
      );
    } else if (permissionKey === "bodegaPaquetes") {
      return (permissionValue & encargado.READ) === encargado.READ;
    } else if (permissionKey === "bodegaBitacora") {
      return (permissionValue & encargado.READ) === encargado.READ;
    }
  } else if (preset === 2 && presidenteDeConsejo.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  } else if (preset === 3 && recepcionPaquetes.includes(permissionKey)) {
    return (
      (permissionValue & recepcion.OPEN) === recepcion.OPEN ||
      (permissionValue & recepcion.MUNICIPAL) === recepcion.MUNICIPAL
    );
  } else if (preset === 4 && recepcionPaquetes.includes(permissionKey)) {
    return (
      (permissionValue & recepcion.OPEN) === recepcion.OPEN ||
      (permissionValue & recepcion.DISTRITAL) === recepcion.DISTRITAL
    );
  } else if (preset === 5 && recepcionPaquetes.includes(permissionKey)) {
    return (
      (permissionValue & recepcion.OPEN) === recepcion.OPEN ||
      (permissionValue & recepcion.GUBERNATURA) === recepcion.GUBERNATURA
    );
  } else if (preset === 6 && presidenteMunicipal.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  } else if (preset === 8 && auxDatosGenerales.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  } else if (preset === 9 && auxCaptura.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  } else if (preset === 10 && auxRecepcion.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  } else if (preset === 11 && auxLectura.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  } else if (preset === 12 && presidenteDistrital.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  } else if (preset === 13 && auxAcreditacion.includes(permissionKey)) {
    return (permissionValue & PERMISSIONS_BASE.READ) === PERMISSIONS_BASE.READ;
  }

  /*BodegaAdministracion*/
  return false;
};

const getPercentage = (firstNum, secondNum) => {
  if (isNaN(firstNum) || isNaN(secondNum)) return "0.00%";
  let result = (firstNum / secondNum) * 100;
  if (isNaN(result)) return "0.00%";
  return `${result.toFixed(2)}%`;
};

const formatDate = (date) => {
  const tmpDate = new Date(date);
  const formattedDate = new Date(
    tmpDate.setHours(tmpDate.getHours() - 7)
  ).toLocaleString("en-US");
  return formattedDate;
};

export {
  capitalizeFirst,
  validateEmail,
  validateRfc,
  validateName,
  validateUser,
  validatePassword,
  validateConfirmPassword,
  formatPhoneNumber,
  propertyAccesor,
  generateDefaultChartOptions,
  checkPermissionPreset,
  getPercentage,
  formatDate,
  generateDefaultChartOptions2D,
};
