import React, { useEffect, useState } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PageLayout from "../../../components/PageLayout";
import Services from "../../../services";
import FullScreenLoader from "../../../components/FullScreenLoader";

import TablePagination from "../../../components/TablePagination";

const urlConsejos = "consejo-electoral?limite=-1";
const urlCasilla =
  "casilla?limite=-1&&expand=distrito,seccion,nombreCompleto,casillaClausura&idConsejo=";

const ReporteClausura = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [gettingCasillas, setGettingCasillas] = useState(false);
  const [consejos, setConsejos] = useState([]);
  const [casillas, setCasillas] = useState([]);

  useEffect(() => {
    let mounted = true;

    const obtenerConsejos = async () => {
      try {
        const response = await Services.get(urlConsejos);

        if (mounted) {
          setConsejos(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    obtenerConsejos();

    return () => (mounted = false);
  }, []);

  if (loading) return <FullScreenLoader />;

  const onChangeConsejos = async (consejo) => {
    if (!consejo) return;

    try {
      setGettingCasillas(true);

      const response = await Services.get(
        urlCasilla + consejo.idConsejoElectoral
      );

      setCasillas(response.resultado);
    } catch (error) {
      console.error(error);
    } finally {
      setGettingCasillas(false);
    }
  };

  return (
    <PageLayout
      title="Reporte Clausura"
      history={history}
      links={[
        {
          to: "/estadisticos/bitacora-escaneo",
          icon: <DescriptionIcon fontSize="small" />,
          label: "Reporte Clausura",
        },
      ]}
    >
      <Autocomplete
        size="small"
        options={consejos}
        getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
        style={{ width: "50%" }}
        renderInput={(params) => (
          <TextField
            style={{ backgroundColor: "white" }}
            {...params}
            label="Consejos Electorales"
            variant="outlined"
          />
        )}
        onChange={async (event, newValue) => await onChangeConsejos(newValue)}
        style={{ marginBottom: 20 }}
      />
      {casillas.length ? (
        <TablePagination
          data={casillas}
          extraRows={[
            {
              prop: "nombreCompleto",
              name: "Casilla",
              cell: (row) => <>{row.nombreCompleto}</>,
            },
            {
              prop: "seccion",
              name: "Sección",
              cell: (row) => (
                <>{row.seccion ? row.seccion.nombre : "Sin Sección"}</>
              ),
            },
            {
              prop: "casillaClausura",
              name: "Clasurado",
              cell: (row) => (
                <>{row.casillaClausura ? "Clausurado" : "Pendiente"}</>
              ),
            },
          ]}
        />
      ) : (
        <Card style={{ padding: 10 }}>
          {gettingCasillas ? (
            <center>
              <CircularProgress size={25} />
            </center>
          ) : (
            <Typography align="center">Sin resultados...</Typography>
          )}
        </Card>
      )}
    </PageLayout>
  );
};

export default ReporteClausura;
