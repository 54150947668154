import firebase from "firebase/app";

const { NODE_ENV: env } = process.env;
//https://dev.computosonora.com/v1/
const baseUrl =
  env === "development"
    ? "https://dev.computosonora.com/v1/"
    : "https://dev.computosonora.com/v1/";

//"https://admin.computosonora.com/v1/";

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      unsubscribe();
      if (user) {
        const token = await user.getIdToken();
        resolve(token);
      }
    }, reject);
  });
};

const getHeaders = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

const getHeadersWhitOutToken = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
});

class Services {
  async get(url) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "GET",
      headers: getHeaders(token),
    });

    return response.json();
  }

  async getWithOutToken(url) {
    const response = await fetch(url, {
      method: "GET",
      headers: getHeadersWhitOutToken(),
    });

    return response.json();
  }

  async post(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async delete(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "DELETE",
      headers: getHeaders(token),
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async getBlob(url, data) {
    const token = await getCurrentToken();
    const response = await fetch(baseUrl + url, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify(data),
    });
    return await response.blob();
  }

  async downloadBlob(url, data, fileName) {
    const blob = await this.getBlob(url, data);
    const urlObj = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = urlObj;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
}

export default new Services();
