import { Button, Grid, Typography } from "@material-ui/core";
import { AddBoxOutlined } from "@material-ui/icons";
import React from "react";
import UrlBreadcrumb from "./UrlBreadcrumb";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
}));

const PageHeader = ({ history, ...props }) => {
  const classes = useStyles();
  const {
    title = "Página sin título",
    links,
    preActionButton,
    actionButton,
  } = props;

  return (
    <>
      {props.dialog ? (
        <></>
      ) : (
        <>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UrlBreadcrumb links={links} history={history} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Grid item xs={12}>
                  {preActionButton && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={(e) => {
                        if (preActionButton.to) {
                          history.push(preActionButton.to);
                        } else if (preActionButton.onClick) {
                          preActionButton.onClick(e);
                        }
                      }}
                      style={{
                        float: "left",
                      }}
                      size="small"
                      endIcon={preActionButton.icon}
                    >
                      {preActionButton.label}
                    </Button>
                  )}
                  {actionButton && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={(e) => {
                        if (actionButton.to) {
                          history.push(actionButton.to);
                        } else if (actionButton.onClick) {
                          actionButton.onClick(e);
                        }
                      }}
                      style={{
                        float: "right",
                      }}
                      size="small"
                      endIcon={actionButton.icon}
                    >
                      {actionButton.label}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default PageHeader;
