import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import HomeCard from "../../components/HomeCard";
import { Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const Reportes = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ display: "inline-block" }}
        >
          <StyledBreadcrumb
            component="a"
            label="Inicio"
            icon={<HomeIcon fontSize="small" />}
            onClick={() => history.push("/")}
          />
          <StyledBreadcrumb
            component="a"
            label="Reunión de Trabajo"
            onClick={() => {
              history.push("/estadisticos");
            }}
          />
        </Breadcrumbs>
        <Grid
          component={Paper}
          container
          spacing={3}
          className={classes.paper}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12} lg={4}>
            <HomeCard
              title="Recepciones Municipales"
              description="Descripción sobre las Recepciones Municipales"
              image="https://api.computosonora.com/images/home/iee_2.png"
              link="paquetes/recepcion-municipal"
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <HomeCard
              title="Recepciones Distritales"
              description="Descripción sobre las Recepciones Distritales"
              image="https://api.computosonora.com/images/home/iee_2.png"
              link="paquetes/recepcion-distrital"
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <HomeCard
              title="Recepciones Federales"
              description="Descripción sobre las Recepciones Federales"
              image="https://api.computosonora.com/images/home/iee_2.png"
              link="paquetes/recepcion-federal"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Reportes;
