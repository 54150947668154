const { NODE_ENV: env } = process.env;

const baseUrl =
  env === "development"
    ? "https://dev.computosonora.com/v1/"
    : "https://dev.computosonora.com/v1/";

const baseUrlPdf =
  env === "development"
    ? "https://dev.computosonora.com/pdf/"
    : "https://dev.computosonora.com/pdf/";

const photoBaseUrl =
  env === "development"
    ? "https://dev.computosonora.com"
    : "https://dev.computosonora.com";

export { baseUrl, photoBaseUrl, baseUrlPdf };
