import React from "react";
import { CloudDownload, GetApp } from "@material-ui/icons";
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import PageLayout from "../../components/PageLayout";
import TablePagination from "../../components/TablePagination";
import { useModels } from "../../hooks/useModels";
import Loading from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
}));

const headerConsejos = [
  {
    prop: "nombre",
    name: "CONSEJO",
  },
  {
    prop: "totalActas",
    name: "ACTAS",
  },
  {
    prop: "totalCotejo",
    name: "COTEJO",
  },
  {
    prop: "totalRecuento",
    name: "RECUENTO",
  },
  {
    prop: "porcentajeRecuento",
    name: "RECUENTO (%)",
  },
  {
    prop: "estatusGeneral",
    name: "ESTATUS",
  },
];

const actasRequest = {
  name: "consejo-electoral",
  extraParams: {
    fields: "nombre,totalActas",
    expand:
      "totalActas,totalCotejo,totalRecuento,porcentajeRecuento,estatusGeneral",
    ordenar: "idConsejoElectoral.asc",
    limite: -1,
  },
};

const EstadisticoCotejoRecuento = ({ history }) => {
  const classes = useStyles();

  const [
    consejosElectorales,
    consejosElectoralesLoading,
    _,
    consejosElectoralesPage,
    refreshConsejosElectorales,
  ] = useModels(actasRequest);

  // const changePage = (page) => {
  //   let params = {
  //     name: "consejo-electoral",
  //     fields: "nombre,totalActas",
  //     expand:
  //       "totalActas,totalCotejo,totalRecuento,porcentajeRecuento,estatusGeneral",
  //     ordenar: "idConsejoElectoral.asc",
  //     // pagina: page + 1,
  //     // limite: 10,
  //     isCargando: false,
  //   };
  //   refreshConsejosElectorales(true, params);
  // };

  const downloadClicked = (_) => {
    // console.log("abrir");
    window.open(
      "https://dev.computosonora.com/v1/excel/estadisticos-cotejo-recuento?idUsuario=fzIxjAB2ZNXtAkolKFAc2wjfmyd2",
      "_blank"
    );
  };

  if (consejosElectoralesLoading) return <Loading />;

  return (
    <PageLayout
      title="ESTADÍSTICOS: ACTAS EN COTEJO O RECUENTO"
      history={history}
      SearchComponents={
        <>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ backgroundColor: "#4caf50", color: "white" }}
              startIcon={<GetApp />}
              onClick={downloadClicked}
              fullWidth
            >
              <Typography>
                <strong>reporte general</strong>
              </Typography>
            </Button>
          </Grid>
        </>
      }
    >
      <Grid
        component={Paper}
        container
        spacing={3}
        className={classes.paper}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <TablePagination
            size="small"
            data={consejosElectorales}
            header={headerConsejos}
            count={
              consejosElectoralesPage !== null
                ? consejosElectoralesPage.total
                : 0
            }
            // paginatedApi
            // paginationPageSize={-1}
            // changePageApi={changePage}
            // extraRows={[
            //   {
            //     prop: "acciones",
            //     name: "REPORTE",
            //     cell: (row, index) => (
            //       <Tooltip title="Descargar reporte en excel">
            //         <IconButton
            //           onClick={(event) => console.log("clicked")}
            //           variant="contained"
            //           style={{ backgroundColor: "#4caf50", color: "white" }}
            //         >
            //           <CloudDownload />
            //         </IconButton>
            //       </Tooltip>
            //     ),
            //   },
            // ]}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default EstadisticoCotejoRecuento;
