import React, { useEffect, useState } from "react";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import PageLayout from "../../../components/PageLayout";
import Services from "../../../services";
import TablePagination from "../../../components/TablePagination";
import Loading from "../../../components/FullScreenLoader";
import { format } from "date-fns";
import { Button, Grid, TextField } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useAuth } from "../../../hooks/useAuth";

const url =
  "bitacora-paquete?expand=acta.nombreConTipoEleccion,agendaEntrega,agendaRecibe&ordenar=fecha.desc";

const MovimientosBodegas = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [bitacorasPaquetes, setBitacorasPaquetes] = useState([]);
  const [totalBitacorasPaquetes, setTotalBitacorasPaquetes] = useState(0);
  const [search, setSearch] = useState("");
  const { user, userLoading } = useAuth();

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      try {
        const response = await Services.get(url);

        if (mounted) {
          setTotalBitacorasPaquetes(response.paginacion.total);
          setBitacorasPaquetes(response.resultado);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    getData();

    return () => (mounted = false);
  }, []);

  if (loading || !user) return <Loading />;

  const changePage = async (page) => {
    try {
      const response = await Services.get(url + "&pagina=" + (page + 1));

      setBitacorasPaquetes(response.resultado);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = async () => {
    try {
      if (searching) return;

      setSearching(true);

      const response = await Services.get(url + "&buscar=" + search);

      setTotalBitacorasPaquetes(response.paginacion.total);
      setBitacorasPaquetes(response.resultado);
    } catch (error) {
      console.log(error);
    } finally {
      setSearching(false);
    }
  };

  const downloadExcel = async () => {
    try {
      await Services.downloadBlob(
        "excel/movimientos-bodegas",
        {
          consejoElectoral: user?.consejoElectoral.nombre,
          idConsejoElectoral: user?.consejoElectoral.idConsejoElectoral
        },
        "MovimientosBodegas.xlsx"
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageLayout
      title={"Movimientos de Bodegas"}
      history={history}
      links={[
        {
          to: "/bodegas/movimientos-de-bodegas",
          icon: <CompareArrowsIcon fontSize="small" />,
          label: "Movimientos de Bodegas",
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item md={10}>
          <TextField
            fullWidth
            label="Buscar"
            size="small"
            variant="outlined"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onSearch}
          >
            BUSCAR
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button
            style={{ backgroundColor: "#4caf50", color: "white" }}
            fullWidth
            variant="contained"
            onClick={downloadExcel}
            startIcon={<GetAppIcon />}
          >
            Excel
          </Button>
        </Grid>
      </Grid>
      <br />
      {searching ? (
        <Loading />
      ) : (
        <TablePagination
          header={[]}
          changePageApi={changePage}
          data={bitacorasPaquetes}
          count={totalBitacorasPaquetes}
          paginatedApi
          extraRows={[
            {
              prop: "acta",
              name: "Nombre del Acta",
              cell: (row) => row.acta.nombreConTipoEleccion,
            },
            {
              prop: "entrega",
              name: "Entrega",
              cell: (row) => row.agendaEntrega?.nombreCompleto,
            },
            {
              prop: "recibe",
              name: "Recibe",
              cell: (row) => row.agendaRecibe?.nombreCompleto,
            },
            {
              prop: "entradaSalida",
              name: "Entrada/Salida",
              cell: (row) => (row.entradaSalida === "E" ? "Entrada" : "Salida"),
            },
            {
              prop: "fecha",
              name: "Fecha",
              cell: (row) => format(new Date(row.fecha), "dd-MM-yyyy hh:mm"),
            },
          ]}
        />
      )}
    </PageLayout>
  );
};

export default MovimientosBodegas;
