import React from "react";
import {
  IconButton,
  Button,
  Grid,
  Menu,
  TextField,
  MenuItem,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InboxIcon from "@material-ui/icons/Inbox";

import TablePagination from "../../components/TablePagination";
import TablePageLayout from "../../components/TablePageLayout";
import { useModels } from "../../hooks/useModels";
import { FindInPage, Print } from "@material-ui/icons";
import { baseUrl, baseUrlPdf } from "../../utils/variables";
import { auth } from "../../firebase";
import PdfDialog from "../../components/PdfDialog";
import { useAuth } from "../../hooks/useAuth";

const tableHeader = [
  {
    prop: "nombreCompletoActa",
    name: "Nombre del acta",
  },
];

const actasRequest = {
  name: "acta",
  fields: "idActa,nombreCompletoActa",
  extraParams: {
    estatusComputo: "con-fecha-computo",
    estatusRecuento: "con-recuento-pleno",
    expand: "fechaRecuento",
  },
};

const RecuentoListado = ({ history }) => {
  const [busqueda, setBusqueda] = React.useState("");
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const id = auth.currentUser.uid;
  const [models, modelsLoading, modelsError, modelsPage, refreshModels] =
    useModels(actasRequest);
  const { user, userLoading } = useAuth();

  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");

  const onSearchClicked = React.useCallback(async () => {
    let params = {};
    if (busqueda.length > 0) params = { ...params, busqueda };
    await refreshModels(false, params);
  }, [busqueda, refreshModels]);

  const changePage = (page) => {
    let params = {
      ...actasRequest,
      pagina: page + 1,
      limite: 20,
      isCargando: false,
      busqueda,
    };
    refreshModels(true, params);
  };

  const descargarReporte = () => {
    window.open(
      "https://api.computosonora.com/pdf/" +
        "computo-final/descargar-boletas?tipoComputo=recuento&idConsejoElectoral=" +
        user?.idConsejoElectoral,
      "_blank"
    );
  };

  return (
    <TablePageLayout
      history={history}
      title="SESIÓN DE CÓMPUTO: HISTORIAL DE ACTAS RECONTADAS"
      SearchComponents={
        <>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Buscar por nombre o tipo"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
              variant="outlined"
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.keyCode === 13) {
                  onSearchClicked();
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSearchClicked}
              disabled={modelsLoading}
              fullWidth
              size="small"
              endIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </>
      }
      tableLoading={modelsLoading || userLoading}
      tableErrors={modelsError}
      preActionButton={
        user?.puesto === "CONSEJERO(A) PRESIDENTE(A)"
          ? {
              icon: <InboxIcon />,
              label: `Descargar reporte`,
              onClick: descargarReporte,
            }
          : undefined
      }
      actionButton={{
        to: `/recuento/nuevo`,
        icon: <InboxIcon />,
        label: `Nuevo recuento`,
      }}
      links={[
        {
          to: `/recuento`,
          icon: <FindInPage fontSize="small" />,
          label: `Historial de recuento`,
        },
      ]}
      TableComponent={
        <TablePagination
          header={tableHeader}
          data={models}
          paginatedApi
          changePageApi={changePage}
          count={modelsPage !== null ? modelsPage.total : 0}
          extraRows={[
            {
              prop: "conRecepcion",
              name: "Fecha de recuento",
              cell: (row) =>
                row.fechaRecuento ? <i>{row.fechaRecuento}</i> : <i>-</i>,
            },
            {
              prop: "acciones",
              name: "Acciones",
              cell: (row, index) => (
                <div>
                  <IconButton
                    onClick={(event) => {
                      setOpenMenuRow(index);
                      setMenuAnchor(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={menuAnchor}
                    keepMounted
                    open={index === openMenuRow}
                    onClose={() => setOpenMenuRow(null)}
                  >
                    <MenuItem
                      onClick={() => {
                        setPdfDialogOpen(true);
                        setLink(
                          `${baseUrlPdf}acta-escrutinio-computo?idActa=${row.idActa}&idUsuario=${id}`
                        );
                      }}
                    >
                      <Print style={{ paddingRight: 5 }} />
                      IMPRIMIR
                    </MenuItem>
                  </Menu>
                  <PdfDialog
                    source={link}
                    open={pdfDialogOpen}
                    setOpen={setPdfDialogOpen}
                  />
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};

export default RecuentoListado;
